<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-03-30 14:59:06
 * @LastEditTime: 2020-05-09 14:08:53
 -->
<template>
  <div class="jepass-shop-video">
    <el-backtop />
    <div
      class="box_top"
    >
      <Header />
    </div>
    <div class="playerPage">
      <div class="je-detail-content">
        <div class="tit_product">
          {{ product.PRODUCT_JYSM }}
        </div>
        <div class="tit_o">
          <div class="tit_left">
            <!-- <span
              class="back_but"
            /> -->
            <span v-if="videoObj.ZYMXB_ZYMC">{{ videoObj.ZYMXB_ZYMC }}</span>
            <span
              v-if="videoObj && !videoObj.ZYMXB_ZYMC"
              @click="openShowVideo"
              style="cursor: pointer;"
            >您没有权限查看此视频，请购买！</span>
          </div>
          <div class="search">
            <el-autocomplete
              :clearable="true"
              :trigger-on-focus="false"
              v-model="keyword"
              ref="SearchAsync"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              @select="handleSelect"
            />
            <span
              class="search_but"
            >搜索</span>
          </div>
        </div>
        <!-- 视频和右侧列表 -->
        <div class="box_o">
          <div
            id="player-con"
            ref="player"
            class="prism-player video_con"
          >
            <!-- <video
              v-if="!PlayAuth.PlayAuth"
              ref="video_1"
              :poster="videoObj.ZYMXB_ZYYLTP"
              controls="controls"
              class="video_con"
            /> -->
          </div>
          <!-- <div
            v-if="!videoObj.url"
            class="video_ton"
          >
            视频正在录制中，敬请期待！
          </div> -->
          <div class="list_con">
            <div class="list_scroll">
              <div
                v-for="(item,index) in videoList.list"
                :key="index"
                class="list_li position-relative"
                :class="item.ZYMXB_DBID == videoObj.ZYMXB_DBID && 'list_act'"
                @click="playVideo(item, index)"
              >
                <span class="vertical-align-middle list_title_omit">
                  {{ index+1 }}.{{ item.ZYMXB_ZYMC }}
                </span>
                <span
                  v-if="!free && isVip && item.ZYMXB_YESORNO_CODE != '1'"
                  class="vipVideoPlay font-size-14 margin-left-10 color-FFA200 line-height-60 vertical-align-middle border-radius-5 padding-LR-4"
                >付费</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="margin-top-40" />
      <Footer />
    </div>
    <div>
      <payVideo
        :video-details="product"
        :show-video="showVideo"
        @closePayVideo="closePayVideo"
      />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer.vue';
import { getResourceData, getProductList4order } from '@/pages/detail/actions/actions';
import payVideo from './payVideo';

export default {
  components: {
    Header,
    payVideo,
    Footer,
  },
  data() {
    return {
      product: {},
      keyword: '',
      productCode: this.$route.query.code,
      videoId: this.$route.query.video,
      vipVideo: {},
      free: false, // 是否免费观看
      isVip: false, // 是否vip观看
      PlayAuth: '', // 当前视频的加密密钥
      showVideo: false, // 购买视频的弹出
      // 当前播放的视频信息
      videoObj: {
      },
      videoList: {
        obj: {
          title: '世界上最有钱的人的人生经验和规划',
          details: '主讲人：全能金牌讲师翟梓钦',
        },
        list: [],
      },
    };
  },
  created() {
    this.getProduct();
  },
  methods: {
    /**
     * 视频查询
     */
    querySearchAsync(queryString, cd) {
      if (!queryString || queryString.length < 2) return;
      let items = this.videoList.list.filter(item => item.ZYMXB_ZYMC.indexOf(queryString) != -1);
      this.$refs.SearchAsync.handleFocus();
      if (items.length == 0) {
        items = [{
          value: '没有查询结果，请重新输入条件',
        }];
        return cd(items);
      }
      cd(items.map(item => ({ value: item.ZYMXB_ZYMC, id: item.ZYMXB_DBID })));
    },
    /**
     * 视频选择播放
     */
    handleSelect(item) {
      if (!item) return;
      const video = this.videoList.list.filter(_item => _item.ZYMXB_DBID == item.id)[0];
      if (video) {
        this.playVideo(video);
      }
    },
    // 视频标题开头是否是数字开头
    rerealNumber(number) {
      const reRealNumber1 = /^[1-9]+[\.]+[\s\S]*$/; // 非零开头
      const reRealNumber2 = /^[0-9]+[\s\S]*$/; // 零开头
      // 零开头
      if (reRealNumber2.test(number)) {
        return number.substring(2);
      }
      // 非零开头的数字
      if (reRealNumber1.test(number)) {
        const numArr = number.split('.');
        const numString = `${numArr[1]}.${numArr[2]}`;
        return numString;
      }
      return number;
    },
    // 获取商品的信息
    getProduct() {
      if (!this.productCode) return;
      getResourceData({ type: 'resource', productCode: this.productCode }).then((res) => {
        if (res.success) {
          const datas = res.rows || res.obj || [];
          this.videoData = datas;
          // 初始化播放视频
          let videoObj = datas[0];
          if (this.videoId) {
            videoObj = datas.filter(item => item.JEPAAS_ZYMXB_ID == this.videoId)[0];
          }
          this.videoList.list = datas.filter((info) => {
            info.ZYMXB_ZYMC = this.rerealNumber(info.ZYMXB_ZYMC);
            return info;
          });
          this.playVideo(videoObj); // 默认自动播放第一个视频
        // this.defaultSelected = this.radioArrData[0];
        }
      });
      // 当前商品信息
      getProductList4order({ productCodes: this.productCode }).then((res) => {
        if (res.success) {
          const info = res.obj[0];
          this.product = info.productInfo;
          this.free = info.productInfo.PRODUCT_CKJG == 0; // 是否免费观看
          this.isVip = info.productInfo.PRODUCT_YESORNO_NAME == '是'; // 是否vip观看
          this.product.buyStatus = info.buyStatus;
        }
      });
    },
    openShowVideo() {
      this.showVideo = true;
    },
    closePayVideo() {
      this.showVideo = false;
    },
    /**
     * 播放视频
     */
    doPlayer(vid, playauth) {
      if (this.player) {
        // if (this.player.replayByVidAndPlayAuth) {
        //   this.player.replayByVidAndPlayAuth(vid, playauth);
        // } else {
        //   this.player.dispose();
        //   delete this.player;
        //   this.$refs.player.el.innerHTML = '';
        //   this.doPlayer(vid, playauth);
        // }
        this.player.dispose();
        delete this.player;
        // this.$refs.player.el.innerHTML = '';
        this.doPlayer(vid, playauth);
      } else {
        this.player = new window.Aliplayer({
          id: 'player-con',
          qualitySort: 'asc',
          format: 'm3u8',
          mediaType: 'video',
          encryptType: 1,
          vid,
          playauth,
          // width: '100%',
          // height: '500px',
          autoplay: true,
          isLive: false,
          cover: '',
          rePlay: true,
          playsinline: true,
          preload: true,
          controlBarVisibility: 'hover',
          useH5Prism: true,
        }, (() => {
          console.log('The player is created');
        }));
      }
    },
    // 点击播放视频
    playVideo(videoObj) {
      this.$ajax({
        url: '/jepaas/vod/getVideoPlayAuth',
        data: {
          videoId: videoObj.ZYMXB_DBID,
        },
      }).then((res) => {
        const data = res.data;
        if (data.success) {
          this.PlayAuth = data.obj;
          this.videoObj = videoObj;
          // eslint-disable-next-line no-undef
          this.doPlayer(videoObj.ZYMXB_DBID, this.PlayAuth.PlayAuth);
        } else if (data.code == '1003') {
          this.openShowVideo();
        } else {
          this.$message({
            message: `${data.message}`,
            type: 'error',
          });
        }
        // 错误信息
        // 1000", "视频不存在"
        // 1002", "视频正在转码，或视频不存在，请稍后重试"
        // 1001", "用户没有登录"
        // 1003", "没有购买商品"
      });
    },
  },
};
</script>


<style lang="less">
.search {
  text-align: center;
  display: flex;
  .el-select .el-input__inner {
    cursor: auto;
  }
  .el-input {
    border: 0px;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    height: 38px;
  }
  input {
    cursor: auto;
    width: 345px;
    font-size: 14px !important;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    height: 38px;
    color: #ffffff;
    border: 0px solid;
    background-color: #696969;
  }
  .search_but {
    cursor: pointer;
    border: 0;
    width: 90px;
    height: 38px;
    background: rgba(255, 48, 65, 1);
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 254, 254, 1);
    line-height: 40px;
  }
}
</style>

<style lang="less" scoped>
@import "../../index/style/shop/util";
.jepass-shop-video {
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .playerPage {
    text-align: center;
    background: rgba(47, 47, 47, 1);
    min-height: calc(100vh - 80px);
    .je-detail-content {
      margin: 0 auto;
      padding: 20px 0;
      width: 1250px;
      .tit_product{
        padding: 20px 0;
        font-size: 28px;
        font-weight: 500;
        color: rgb(255, 255, 255);
      }
      .tit_o {
        width: 1200px;
        margin: auto;
        height: 86px;
        font-size: 22px;
        font-weight: 500;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tit_left {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .back_but {
            cursor: pointer;
            display: inline-block;
            margin: 10px;
            border-left: 2px solid;
            border-bottom: 2px solid;
            width: 15px;
            height: 15px;
            transform: rotate(45deg);
          }
        }
      }
    }
    // 视频开始的样式
    .box_o {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .video_ton {
        width: 100%;
        color: #fff;
        padding-top: 100px;
        width: 855px;
        height: 462px;
      }
      .video_con {
        object-fit: contain;
        width: 855px!important;
        height: 462px!important;
      }
      .list_con {
        width: 345px;
        height: 462px;
        background: rgba(17, 17, 17, 0.4);
        text-align: left;
        // padding: 12px 0;
        .level_o {
          height: 69px;
          padding: 0px 20px;
          display: line-block;
          font-size: 18px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          line-height: 28px;
          .titleTwo_o {
            font-size: 16px;
          }
        }
        .list_scroll {
          height: 100%;
          overflow: scroll;
          .list_li {
            width: 100%;
            cursor: pointer;
            padding: 0px 20px;
            height: 66px;
            line-height: 66px;
            font-size: 14px;
            font-weight: 400;
            color: rgb(255, 255, 255);
            &:hover {
              background: rgba(0, 0, 0, 1);
              border-right: 2px solid #fff;
            }
            .list_title_omit {
              // display: inline-block;
              width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
            .vipVideoPlay {
              position: absolute;
              height: 30px;
              line-height: 30px;
              top: 16px;
              right: 60px;
              padding: 0 10px;
            }
          }
          .list_act {
            background: rgba(0, 0, 0, 1);
            color: rgba(255, 48, 65, 1);
            border-right: 2px solid #fff;
          }
        }
      }
    }
  }
}
</style>
